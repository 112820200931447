.update-version-snackbar {
	background: white;
	color: black;
	border: 0;
	border-top-width: 13px;
	border-style: solid;
	border-image: linear-gradient(90deg, rgba(23, 161, 255, 1) 0%, rgba(17, 175, 255, 1) 54%, rgba(0, 212, 255, 1) 79%);
	border-image-slice: 1;
	position: absolute;
	top: 69px;
	right: 0;
	.message-icon-wrapper {
		color: black !important;
	}
}
.success-snackbar {
	background: rgb(220 236 224);
	border: 2px solid #39b45a;
	border-radius: 4px !important;
	padding: 16px !important;
	position: absolute;
	top: 69px;
	right: 0px;
	color: #212121;
	box-shadow: 2px 10px 16px 0px #cbcbcb84;
	.icon-only {
		color: #39b45a;
	}
	.message-icon-wrapper {
		background-color: #39b45a;
	}
	.mat-simple-snackbar-action {
		color: #39b45a;
	}
}

.error-snackbar {
	background: #fff2f2;
	border: 2px solid #f00;
	border-radius: 4px !important;
	padding: 16px !important;
	position: absolute;
	top: 69px;
	right: 0px;
	color: #212121;
	font-weight: normal;
	box-shadow: 2px 10px 16px 0px #cbcbcb84;
	.icon-only {
		color: #db2323;
	}
	.message-icon-wrapper {
		background-color: #db2323;
	}
	.mat-simple-snackbar-action {
		color: #db2323;
	}
}

.warn-snackbar {
	background: #fff7e9;
	border: 2px solid #ef9400;
	border-radius: 0 !important;
	padding: 16px !important;
	position: absolute;
	top: 69px;
	right: 0px;
	color: #212121;
	box-shadow: 2px 10px 16px 0px #cbcbcb84;
	.icon-only {
		color: #ef9400;
	}
	.message-icon-wrapper {
		background-color: #ef9400;
	}
	.mat-simple-snackbar-action {
		color: #ef9400;
	}
}

.info-snackbar {
	background: #e5effa;
	border: 2px solid #0167d9;
	border-radius: 4px !important;
	padding: 16px !important;
	position: absolute;
	top: 69px;
	right: 0px;
	color: #212121;
	box-shadow: 2px 10px 16px 0px #cbcbcb84;
	.icon-only {
		color: #0167d9;
	}
	.message-icon-wrapper {
		background-color: #0167d9;
	}
	.mat-simple-snackbar-action {
		color: #0167d9;
	}
}
