@import './ngx-common/styles/variables.scss';
@import './ngx-common/styles/media-queries.mixins.scss';
@import './ngx-common/styles/custom-snackbar.scss';
@import './ngx-common-v2/styles/cls-mat-design.scss';
@import './ngx-common-v2/styles/website-colors.styles.scss';
@import './ngx-common-v2/styles/common.styles.scss';
:root {
	--app-height: 100%;
}

html,
body {
	height: 100%;
	@include on-phone-screen {
		height: calc(var(--app-height, 1vh) * 100); // to take the full height of the viewport(dynamic-old browser)
		height: 100dvh; // to take the full height of the viewport(dynamic)
	}
}
body {
	margin: 0;
	font-family: 'haboro-soft';
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $color-black;
	font-family: 'Almarai';
	font-style: normal;
	line-height: normal;
	text-transform: capitalize;
}
p {
	color: $color-black;
}

h1 {
	font-size: 24px;
	font-weight: 800;
}

h2 {
	font-size: 20px;
	font-weight: 700;
}

h3,
h4,
h5,
h6 {
	font-size: 18px;
	font-weight: 700;
}

a {
	text-decoration: none;
}

.cl-mat-checkbox {
	.mat-checkbox-layout {
		align-items: flex-start;
	}
	.mat-checkbox-label {
		white-space: pre-wrap !important;
	}
	.mat-checkbox-inner-container {
		margin: 5px 8px 0 0;
	}
}

// #region mat-form-field helpers

mat-form-field {
	width: 100%;
	.mat-form-field-subscript-wrapper {
		margin-top: 4px;
	}
}

.mat-progress-bar-success {
	.mat-progress-bar-fill::after {
		background-color: green;
		// background-color: #17a1ff;
	}
}
.mat-progress-bar-warn {
	.mat-progress-bar-fill::after {
		background-color: orange;
	}
}
.mat-progress-bar-danger {
	.mat-progress-bar-fill::after {
		background-color: #f44336;
	}
}

.mat-tab-link {
	padding: 0 35px !important;
}

.mat-form-field-label {
	color: rgba(0, 0, 0, 0.41);
}

.cdk-overlay-dark-backdrop {
	background: rgba(0, 0, 0, 0.7);
}

.mat-form-field-infix {
	line-height: 1.2;
}

.form-field-outline-no-label {
	.mat-form-field-infix {
		padding-top: 8px !important;
	}

	input {
		height: 22px !important;
	}

	.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
		transform: translateY(3%);
	}
}
.cdk-overlay-pane {
	max-width: 95vw !important;
}

.mat-form-fild-no-bottom-padding {
	.mat-form-field-wrapper {
		padding-bottom: 0 !important;
	}
}

.mat-form-field-outline {
	background-color: white;
}
// #endregion mat-form-field helpers

[padding] {
	padding: 24px;
}
[padding-top] {
	padding-top: 15px;
}
[padding-bottom] {
	padding-bottom: 15px;
}

[no-padding] {
	padding: 0 !important;
}

.mat-button-with-icon {
	padding-left: 7px !important;
}

.app-box-shadow {
	box-shadow: 0px 0px 8px 4px rgb(0 0 0 / 6%);
}

.error-snackbar {
	top: 0;
}

[full-width] {
	width: 100%;
}

[responsive-container] {
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	box-sizing: border-box;
}

.mat-tooltip {
	font-size: 14px;
}

.mat-dialog-no-padding {
	.mat-dialog-container {
		padding: 0 !important;
	}
}

.mat-menu-panel {
	min-height: unset !important;
}

.mat-menu-panel {
	max-width: unset !important;
}

.mat-menu-trigger-hidden {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0;
	height: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

.navbar-advanced-menu {
	.mat-menu-content:not(:empty) {
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
}

.mat-dialog-no-overflow {
	.mat-dialog-container {
		overflow: hidden !important;
	}
}

.update-version-snackbar,
.success-snackbar,
.error-snackbar,
.warn-snackbar,
.info-snackbar {
	top: 10px;
}

.copyleaks-a-tag {
	color: #337ab7 !important;
	text-decoration: none;
	cursor: pointer;
}

[text-center],
.text-center {
	text-align: center;
}

.mat-dialog-no-overflow {
	.mat-dialog-container {
		overflow: hidden !important;
	}
}

.mat-dialog-enable-mfa {
	.mat-dialog-container {
		padding: 32px 67px !important;
	}
}

[no-margin-top] {
	margin-top: 0 !important;
}

.identity-dialog-mobile {
	@include on-medium-screen {
		max-width: 95vw !important;
		margin-right: 4px;
	}
}

[help-icon] {
	color: #040f21;
	font-size: 20px !important;
	width: 20px !important;
	height: 20px !important;
	line-height: 20px !important;
}

.mat-check-box-text-pre-wrap {
	.mat-checkbox-label {
		white-space: pre-wrap !important;
	}
}

.mat-dialog-custom-payment-dialog {
	max-width: 90vw !important;
	max-height: 95vh !important;
	.mat-dialog-container {
		padding: 0px;
	}
}

::-webkit-scrollbar {
	width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f2f6f9;
	border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #c9d6df;
	border-radius: 5px;
}
