/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * haboro-soft:
 *   - http://typekit.com/eulas/00000000000000007735ad5b
 *   - http://typekit.com/eulas/00000000000000007735ad5e
 *   - http://typekit.com/eulas/00000000000000007735ad79
 *   - http://typekit.com/eulas/00000000000000007735ad7b
 *
 * © 2009-2023 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2023-04-27 19:58:47 UTC"}*/

@import url('https://p.typekit.net/p.css?s=1&k=waz7wks&ht=tk&f=34505.34762.34763.34764.34765.34766.34767.34768.34769.34770.34771.34772.34773.34774.34775.34776.34777.34778.34779.34780.34781.34782.34783.34784.34785.34786.34787.34788.34789.34790.34791.34792.34793.34794.34795.34796.34797.34798.34799.34800.34801.34802&a=120118335&app=typekit&e=css');

@font-face {
	font-family: 'haboro-soft';
	src: url('https://use.typekit.net/af/918d82/00000000000000007735ad58/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/918d82/00000000000000007735ad58/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff'),
		url('https://use.typekit.net/af/918d82/00000000000000007735ad58/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft';
	src: url('https://use.typekit.net/af/ef3c52/00000000000000007735ad5b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/ef3c52/00000000000000007735ad5b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3')
			format('woff'),
		url('https://use.typekit.net/af/ef3c52/00000000000000007735ad5b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 200;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft';
	src: url('https://use.typekit.net/af/521a26/00000000000000007735ad5e/30/l?subset_id=2&fvd=i2&v=3') format('woff2'),
		url('https://use.typekit.net/af/521a26/00000000000000007735ad5e/30/d?subset_id=2&fvd=i2&v=3') format('woff'),
		url('https://use.typekit.net/af/521a26/00000000000000007735ad5e/30/a?subset_id=2&fvd=i2&v=3') format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 200;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft';
	src: url('https://use.typekit.net/af/6b87ae/00000000000000007735ad68/30/l?subset_id=2&fvd=i3&v=3') format('woff2'),
		url('https://use.typekit.net/af/6b87ae/00000000000000007735ad68/30/d?subset_id=2&fvd=i3&v=3') format('woff'),
		url('https://use.typekit.net/af/6b87ae/00000000000000007735ad68/30/a?subset_id=2&fvd=i3&v=3') format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 300;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft';
	src: url('https://use.typekit.net/af/70a836/00000000000000007735ad63/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/70a836/00000000000000007735ad63/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
			format('woff'),
		url('https://use.typekit.net/af/70a836/00000000000000007735ad63/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 300;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft';
	src: url('https://use.typekit.net/af/b9b58e/00000000000000007735ad66/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/b9b58e/00000000000000007735ad66/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
			format('woff'),
		url('https://use.typekit.net/af/b9b58e/00000000000000007735ad66/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
			format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft';
	src: url('https://use.typekit.net/af/203813/00000000000000007735ad69/30/l?subset_id=2&fvd=i5&v=3') format('woff2'),
		url('https://use.typekit.net/af/203813/00000000000000007735ad69/30/d?subset_id=2&fvd=i5&v=3') format('woff'),
		url('https://use.typekit.net/af/203813/00000000000000007735ad69/30/a?subset_id=2&fvd=i5&v=3') format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 500;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft';
	src: url('https://use.typekit.net/af/afca7d/00000000000000007735ad6c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/afca7d/00000000000000007735ad6c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
			format('woff'),
		url('https://use.typekit.net/af/afca7d/00000000000000007735ad6c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 500;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft';
	src: url('https://use.typekit.net/af/c8d3dd/00000000000000007735ad6f/30/l?subset_id=2&fvd=i6&v=3') format('woff2'),
		url('https://use.typekit.net/af/c8d3dd/00000000000000007735ad6f/30/d?subset_id=2&fvd=i6&v=3') format('woff'),
		url('https://use.typekit.net/af/c8d3dd/00000000000000007735ad6f/30/a?subset_id=2&fvd=i6&v=3') format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 600;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft';
	src: url('https://use.typekit.net/af/af5398/00000000000000007735ad73/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/af5398/00000000000000007735ad73/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
			format('woff'),
		url('https://use.typekit.net/af/af5398/00000000000000007735ad73/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 600;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft';
	src: url('https://use.typekit.net/af/c99e0e/00000000000000007735ad79/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/c99e0e/00000000000000007735ad79/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff'),
		url('https://use.typekit.net/af/c99e0e/00000000000000007735ad79/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 700;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft';
	src: url('https://use.typekit.net/af/90ed13/00000000000000007735ad7b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/90ed13/00000000000000007735ad7b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
			format('woff'),
		url('https://use.typekit.net/af/90ed13/00000000000000007735ad7b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
			format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 700;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft';
	src: url('https://use.typekit.net/af/ff14f8/00000000000000007735ad7d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/ff14f8/00000000000000007735ad7d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
			format('woff'),
		url('https://use.typekit.net/af/ff14f8/00000000000000007735ad7d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 800;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft';
	src: url('https://use.typekit.net/af/a79ca4/00000000000000007735ad7f/30/l?subset_id=2&fvd=i8&v=3') format('woff2'),
		url('https://use.typekit.net/af/a79ca4/00000000000000007735ad7f/30/d?subset_id=2&fvd=i8&v=3') format('woff'),
		url('https://use.typekit.net/af/a79ca4/00000000000000007735ad7f/30/a?subset_id=2&fvd=i8&v=3') format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 800;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-condensed';
	src: url('https://use.typekit.net/af/4620a5/00000000000000007735ad59/30/l?subset_id=2&fvd=n2&v=3') format('woff2'),
		url('https://use.typekit.net/af/4620a5/00000000000000007735ad59/30/d?subset_id=2&fvd=n2&v=3') format('woff'),
		url('https://use.typekit.net/af/4620a5/00000000000000007735ad59/30/a?subset_id=2&fvd=n2&v=3') format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 200;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-condensed';
	src: url('https://use.typekit.net/af/c8750c/00000000000000007735ad5c/30/l?subset_id=2&fvd=i2&v=3') format('woff2'),
		url('https://use.typekit.net/af/c8750c/00000000000000007735ad5c/30/d?subset_id=2&fvd=i2&v=3') format('woff'),
		url('https://use.typekit.net/af/c8750c/00000000000000007735ad5c/30/a?subset_id=2&fvd=i2&v=3') format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 200;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-condensed';
	src: url('https://use.typekit.net/af/e322dc/00000000000000007735ad5f/30/l?subset_id=2&fvd=n3&v=3') format('woff2'),
		url('https://use.typekit.net/af/e322dc/00000000000000007735ad5f/30/d?subset_id=2&fvd=n3&v=3') format('woff'),
		url('https://use.typekit.net/af/e322dc/00000000000000007735ad5f/30/a?subset_id=2&fvd=n3&v=3') format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 300;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-condensed';
	src: url('https://use.typekit.net/af/b81e2c/00000000000000007735ad61/30/l?subset_id=2&fvd=i3&v=3') format('woff2'),
		url('https://use.typekit.net/af/b81e2c/00000000000000007735ad61/30/d?subset_id=2&fvd=i3&v=3') format('woff'),
		url('https://use.typekit.net/af/b81e2c/00000000000000007735ad61/30/a?subset_id=2&fvd=i3&v=3') format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 300;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-condensed';
	src: url('https://use.typekit.net/af/8637d4/00000000000000007735ad64/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/8637d4/00000000000000007735ad64/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff'),
		url('https://use.typekit.net/af/8637d4/00000000000000007735ad64/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-condensed';
	src: url('https://use.typekit.net/af/c6f97c/00000000000000007735ad67/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/c6f97c/00000000000000007735ad67/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
			format('woff'),
		url('https://use.typekit.net/af/c6f97c/00000000000000007735ad67/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
			format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-condensed';
	src: url('https://use.typekit.net/af/ad2ae0/00000000000000007735ad6b/30/l?subset_id=2&fvd=i5&v=3') format('woff2'),
		url('https://use.typekit.net/af/ad2ae0/00000000000000007735ad6b/30/d?subset_id=2&fvd=i5&v=3') format('woff'),
		url('https://use.typekit.net/af/ad2ae0/00000000000000007735ad6b/30/a?subset_id=2&fvd=i5&v=3') format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 500;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-condensed';
	src: url('https://use.typekit.net/af/ffafa8/00000000000000007735ad6e/30/l?subset_id=2&fvd=n5&v=3') format('woff2'),
		url('https://use.typekit.net/af/ffafa8/00000000000000007735ad6e/30/d?subset_id=2&fvd=n5&v=3') format('woff'),
		url('https://use.typekit.net/af/ffafa8/00000000000000007735ad6e/30/a?subset_id=2&fvd=n5&v=3') format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 500;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-condensed';
	src: url('https://use.typekit.net/af/39de6a/00000000000000007735ad71/30/l?subset_id=2&fvd=n6&v=3') format('woff2'),
		url('https://use.typekit.net/af/39de6a/00000000000000007735ad71/30/d?subset_id=2&fvd=n6&v=3') format('woff'),
		url('https://use.typekit.net/af/39de6a/00000000000000007735ad71/30/a?subset_id=2&fvd=n6&v=3') format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 600;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-condensed';
	src: url('https://use.typekit.net/af/6a9e9f/00000000000000007735ad74/30/l?subset_id=2&fvd=i6&v=3') format('woff2'),
		url('https://use.typekit.net/af/6a9e9f/00000000000000007735ad74/30/d?subset_id=2&fvd=i6&v=3') format('woff'),
		url('https://use.typekit.net/af/6a9e9f/00000000000000007735ad74/30/a?subset_id=2&fvd=i6&v=3') format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 600;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-condensed';
	src: url('https://use.typekit.net/af/1cdb01/00000000000000007735ad75/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/1cdb01/00000000000000007735ad75/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
			format('woff'),
		url('https://use.typekit.net/af/1cdb01/00000000000000007735ad75/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
			format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 700;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-condensed';
	src: url('https://use.typekit.net/af/248e32/00000000000000007735ad76/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/248e32/00000000000000007735ad76/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff'),
		url('https://use.typekit.net/af/248e32/00000000000000007735ad76/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 700;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-condensed';
	src: url('https://use.typekit.net/af/2ee464/00000000000000007735ad77/30/l?subset_id=2&fvd=n8&v=3') format('woff2'),
		url('https://use.typekit.net/af/2ee464/00000000000000007735ad77/30/d?subset_id=2&fvd=n8&v=3') format('woff'),
		url('https://use.typekit.net/af/2ee464/00000000000000007735ad77/30/a?subset_id=2&fvd=n8&v=3') format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 800;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-condensed';
	src: url('https://use.typekit.net/af/467761/00000000000000007735ad78/30/l?subset_id=2&fvd=i8&v=3') format('woff2'),
		url('https://use.typekit.net/af/467761/00000000000000007735ad78/30/d?subset_id=2&fvd=i8&v=3') format('woff'),
		url('https://use.typekit.net/af/467761/00000000000000007735ad78/30/a?subset_id=2&fvd=i8&v=3') format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 800;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-extended';
	src: url('https://use.typekit.net/af/4920e7/00000000000000007735ad5a/30/l?subset_id=2&fvd=n2&v=3') format('woff2'),
		url('https://use.typekit.net/af/4920e7/00000000000000007735ad5a/30/d?subset_id=2&fvd=n2&v=3') format('woff'),
		url('https://use.typekit.net/af/4920e7/00000000000000007735ad5a/30/a?subset_id=2&fvd=n2&v=3') format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 200;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-extended';
	src: url('https://use.typekit.net/af/067003/00000000000000007735ad5d/30/l?subset_id=2&fvd=i2&v=3') format('woff2'),
		url('https://use.typekit.net/af/067003/00000000000000007735ad5d/30/d?subset_id=2&fvd=i2&v=3') format('woff'),
		url('https://use.typekit.net/af/067003/00000000000000007735ad5d/30/a?subset_id=2&fvd=i2&v=3') format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 200;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-extended';
	src: url('https://use.typekit.net/af/8b2ab3/00000000000000007735ad60/30/l?subset_id=2&fvd=i3&v=3') format('woff2'),
		url('https://use.typekit.net/af/8b2ab3/00000000000000007735ad60/30/d?subset_id=2&fvd=i3&v=3') format('woff'),
		url('https://use.typekit.net/af/8b2ab3/00000000000000007735ad60/30/a?subset_id=2&fvd=i3&v=3') format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 300;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-extended';
	src: url('https://use.typekit.net/af/26c062/00000000000000007735ad62/30/l?subset_id=2&fvd=n3&v=3') format('woff2'),
		url('https://use.typekit.net/af/26c062/00000000000000007735ad62/30/d?subset_id=2&fvd=n3&v=3') format('woff'),
		url('https://use.typekit.net/af/26c062/00000000000000007735ad62/30/a?subset_id=2&fvd=n3&v=3') format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 300;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-extended';
	src: url('https://use.typekit.net/af/6d03fb/00000000000000007735ad65/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/6d03fb/00000000000000007735ad65/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
			format('woff'),
		url('https://use.typekit.net/af/6d03fb/00000000000000007735ad65/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
			format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-extended';
	src: url('https://use.typekit.net/af/2658fa/00000000000000007735ad6a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/2658fa/00000000000000007735ad6a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff'),
		url('https://use.typekit.net/af/2658fa/00000000000000007735ad6a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-extended';
	src: url('https://use.typekit.net/af/0168d2/00000000000000007735ad6d/30/l?subset_id=2&fvd=n5&v=3') format('woff2'),
		url('https://use.typekit.net/af/0168d2/00000000000000007735ad6d/30/d?subset_id=2&fvd=n5&v=3') format('woff'),
		url('https://use.typekit.net/af/0168d2/00000000000000007735ad6d/30/a?subset_id=2&fvd=n5&v=3') format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 500;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-extended';
	src: url('https://use.typekit.net/af/b2690b/00000000000000007735ad70/30/l?subset_id=2&fvd=i5&v=3') format('woff2'),
		url('https://use.typekit.net/af/b2690b/00000000000000007735ad70/30/d?subset_id=2&fvd=i5&v=3') format('woff'),
		url('https://use.typekit.net/af/b2690b/00000000000000007735ad70/30/a?subset_id=2&fvd=i5&v=3') format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 500;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-extended';
	src: url('https://use.typekit.net/af/03a1a0/00000000000000007735ad72/30/l?subset_id=2&fvd=n6&v=3') format('woff2'),
		url('https://use.typekit.net/af/03a1a0/00000000000000007735ad72/30/d?subset_id=2&fvd=n6&v=3') format('woff'),
		url('https://use.typekit.net/af/03a1a0/00000000000000007735ad72/30/a?subset_id=2&fvd=n6&v=3') format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 600;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-extended';
	src: url('https://use.typekit.net/af/59bbee/00000000000000007735ad7a/30/l?subset_id=2&fvd=i6&v=3') format('woff2'),
		url('https://use.typekit.net/af/59bbee/00000000000000007735ad7a/30/d?subset_id=2&fvd=i6&v=3') format('woff'),
		url('https://use.typekit.net/af/59bbee/00000000000000007735ad7a/30/a?subset_id=2&fvd=i6&v=3') format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 600;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-extended';
	src: url('https://use.typekit.net/af/e26a8a/00000000000000007735ad7c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/e26a8a/00000000000000007735ad7c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff'),
		url('https://use.typekit.net/af/e26a8a/00000000000000007735ad7c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 700;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-extended';
	src: url('https://use.typekit.net/af/862a57/00000000000000007735ad7e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/862a57/00000000000000007735ad7e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
			format('woff'),
		url('https://use.typekit.net/af/862a57/00000000000000007735ad7e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
			format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 700;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-extended';
	src: url('https://use.typekit.net/af/6024b6/00000000000000007735ad80/30/l?subset_id=2&fvd=n8&v=3') format('woff2'),
		url('https://use.typekit.net/af/6024b6/00000000000000007735ad80/30/d?subset_id=2&fvd=n8&v=3') format('woff'),
		url('https://use.typekit.net/af/6024b6/00000000000000007735ad80/30/a?subset_id=2&fvd=n8&v=3') format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 800;
	font-stretch: normal;
}

@font-face {
	font-family: 'haboro-soft-extended';
	src: url('https://use.typekit.net/af/3d5d6b/00000000000000007735ad81/30/l?subset_id=2&fvd=i8&v=3') format('woff2'),
		url('https://use.typekit.net/af/3d5d6b/00000000000000007735ad81/30/d?subset_id=2&fvd=i8&v=3') format('woff'),
		url('https://use.typekit.net/af/3d5d6b/00000000000000007735ad81/30/a?subset_id=2&fvd=i8&v=3') format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 800;
	font-stretch: normal;
}

.tk-haboro-soft {
	font-family: 'haboro-soft', sans-serif;
}
.tk-haboro-soft-condensed {
	font-family: 'haboro-soft-condensed', sans-serif;
}
.tk-haboro-soft-extended {
	font-family: 'haboro-soft-extended', sans-serif;
}
