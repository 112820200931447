@mixin on-small-screen {
	@media screen and (max-width: 400px) {
		@content;
	}
}

@mixin on-small-height-screen {
	@media screen and (max-height: 716px) {
		@content;
	}
}

@mixin on-medium-screen {
	@media screen and (max-width: 960px) {
		@content;
	}
}

@mixin on-large-screen {
	@media screen and (min-width: 960px) {
		@content;
	}
}

@mixin on-smaller-than-laptop-screen {
	@media screen and (max-width: 1279px) {
		@content;
	}
}

@mixin on-laptop-screen {
	@media screen and (min-width: 1200px) and (max-width: 1500px) {
		@content;
	}
}

@mixin on-extra-large-screen {
	@media screen and (min-width: 1920px) {
		@content;
	}
}

@mixin on-responsive-font-screen {
	@media screen and (max-width: 650px) {
		@content;
	}
}

@mixin on-tablet-screen {
	@media screen and (max-width: 960px) and (min-width: 540px) {
		@content;
	}
}

@mixin on-phone-screen {
	@media screen and (max-width: 539px) {
		@content;
	}
}

@mixin on-min-desktop-screen {
	@media only screen and (max-width: 1112px) and (min-width: 960px) {
		@content;
	}
}

@mixin on-medium-desktop-screen {
	@media only screen and (max-width: 1300px) and (min-width: 960px) {
		@content;
	}
}

@mixin safari-only {
	@media not all and (min-resolution: 0.001dpcm) {
		@supports (-webkit-appearance: none) {
			@content;
		}
	}
}
