@use '@angular/material' as mat;
@import '@angular/material/theming';
//@import "~@mat-datetimepicker/core/datetimepicker/datetimepicker-theme.scss";

$custom-typography: mat.define-typography-config(
	$font-family: haboro-soft,
);

@include mat.core($custom-typography);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$mat-copyleaks: (
	50: #ffebee,
	100: #bbdefb,
	200: #90caf9,
	300: #64b5f6,
	400: #42a5f5,
	500: #17a1ff,
	// primary // 2196F3
	600: #1e88e5,
	700: #1976d2,
	800: #1565c0,
	900: #0d47a1,
	A100: #82b1ff,
	A200: #448aff,
	A400: #2979ff,
	A700: #2962ff,
	AGreen: #2cc54e,
	AYellow: #ffd740,
	contrast: (
		50: $dark-primary-text,
		100: $dark-primary-text,
		200: $dark-primary-text,
		300: $light-primary-text,
		400: $light-primary-text,
		500: $light-primary-text,
		600: $light-primary-text,
		700: $light-primary-text,
		800: $light-primary-text,
		900: $light-primary-text,
		A100: $dark-primary-text,
		A200: $light-primary-text,
		A400: $light-primary-text,
		A700: $light-primary-text,
	),
);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat.define-palette($mat-copyleaks);
$candy-app-accent: mat.define-palette($mat-copyleaks);
// The warn palette is optional (defaults to red).
$candy-app-warn: mat.define-palette(mat.$red-palette);
// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($candy-app-theme);
//@include mat-datetimepicker-theme($candy-app-theme);

.mat-button-toggle-group {
	// border-color: mat-color($candy-app-accent);
	.mat-button-toggle-checked {
		background-color: mat.get-color-from-palette($candy-app-accent);
		color: white;
	}
	.mat-button-toggle-disabled.mat-button-toggle-checked {
		background-color: #bdbdbd;
	}
	.mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):hover .mat-button-toggle-focus-overlay {
		opacity: 0 !important;
	}
}

.mat-badge-peach {
	.mat-badge-content {
		background: #ff8576;
		color: #ffffff;
	}
}

.notification-bell-icon {
	line-height: 0px;
	.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
		left: -1px;
	}

	.mat-badge-medium.mat-badge-above .mat-badge-content {
		top: 0px;
	}

	.mat-badge-medium .mat-badge-content {
		width: 16px;
		height: 16px;
		line-height: 16px;
		box-shadow: 2px 2px 3px #00000029;
	}

	.mat-badge-content {
		font-size: 8px;
	}
}

.unread-Notification-badge {
	line-height: 0px;
	.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
		left: 0px;
	}

	.mat-badge-medium.mat-badge-above .mat-badge-content {
		top: 0px;
	}

	.mat-badge-medium .mat-badge-content {
		width: 8px;
		height: 8px;
		line-height: 8px;
		box-shadow: 2px 2px 3px #00000029;
	}

	.mat-badge-content {
		color: transparent;
	}
}
